import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix } from "../utils";
import getDateFromIso from "../utils/getDateFromIso";
import Helmet from "react-helmet";
import { css } from "styled-components/macro";
import Link from "../utils/link";
import CtaBlock from "../components/CtaBlock";
import media from "../styleUtils/media";

export default class Post extends React.Component {
  render() {
    const { frontmatter } = this.props?.pageContext || {};
    const authorName = this.props?.pageContext?.frontmatter?.author;

    const author = _.get(this.props, "pageContext.pages").find(
      (page) => _.get(page, "frontmatter.title") === authorName
    );
    const ctaBlockData = _.get(this.props, "pageContext.pages")
      .find((page) => page?.url === "/")
      .frontmatter?.sections?.find((section) => section.type === "ctablock");
    const title = _.get(this.props, "pageContext.frontmatter.title");
    const { siteUrl: originUrl } =
      this.props?.pageContext?.site?.siteMetadata || {};
    const profilePageUrl = author?.url ? `${originUrl}${author.url}` : "";

    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {title && title + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>

          <meta property="og:type" content="article" />
          {authorName && <meta name="author" content={authorName} />}
          {profilePageUrl && (
            <meta property="article:author" content={profilePageUrl} />
          )}
          {frontmatter?.date && (
            <meta
              property="article:published_time"
              content={frontmatter?.date}
            />
          )}
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post post-full">
              <header className="post-header">
                <h1 className="post-title">{title}</h1>
              </header>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      )
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <h4 className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </h4>
              )}

              {author && (
                <Link
                  to={author.url}
                  css={css`
                    margin-left: 1.25rem;
                    ${media.tablet`
                      margin-left: 0;
                    `};

                    margin-bottom: 1.25rem;
                    display: inline-flex;
                    border: none;
                    &&:hover {
                      color: unset;
                    }
                  `}
                >
                  <img
                    src={author.frontmatter.thumb_img_path}
                    alt={`${authorName} ${author.frontmatter.job_position}`}
                    css={css`
                      height: 3.5rem;
                      width: 3.5rem;
                      border-radius: 4rem;
                      object-fit: cover;
                      margin-right: 0.75rem;
                      flex-shrink: 0;
                    `}
                  />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    `}
                  >
                    <h4
                      css={css`
                        margin-bottom: 0.25rem;
                        font-size: 1rem;
                      `}
                    >
                      {authorName}
                    </h4>
                    <span
                      css={css`
                        font-size: 0.75rem;
                        margin-bottom: 0;
                      `}
                    >
                      {author.frontmatter.job_position}
                    </span>
                  </div>
                </Link>
              )}

              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
              <footer className="post-meta">
                <time
                  className="published"
                  dateTime={_.get(this.props, "pageContext.frontmatter.date")}
                >
                  {getDateFromIso(
                    _.get(this.props, "pageContext.frontmatter.date")
                  )}
                </time>
              </footer>
            </article>
          </div>
        </div>
        {ctaBlockData && <CtaBlock section={ctaBlockData} />}
      </Layout>
    );
  }
}
